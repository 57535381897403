<template>
  <v-container>
    <v-app-bar dark app color="primary" clipped-right>
      <v-app-bar-nav-icon>
        <v-icon>mdi-account</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Personen</v-toolbar-title>
      <v-spacer />
      <SearchField v-model="search" hint="Name, Kürzel, Klasse"></SearchField>
    </v-app-bar>
    <v-row>
      <v-col>
        <v-card v-if="person">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <PersonName :value="person" :title="true" class="text-h5" />
              </v-list-item-title>
              <v-list-item-subtitle>
                <span v-if="person.code"
                  ><span class="text--primary">{{ person.code }}</span> —
                </span>
                {{ subtitle }}
                <CopyButton v-if="person.eventoId" :value="person.eventoId" />
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-avatar size="92">
              <PortraitImage v-if="person" :value="person" dialog />
            </v-list-item-avatar>
          </v-list-item>
          <v-divider />
          <v-alert v-if="person.dataLock" type="warning">
            <b>Datensperrung:</b> Die Daten dieser Person dürfen nicht an Dritte
            weitergegeben werden.
          </v-alert>
          <v-alert v-if="person.namesakeNumber > 0" type="warning">
            <b>Name nicht eindeutig:</b> Es sind mehrere Personen mit dem
            gleichen Namen vorhanden.
          </v-alert>
          <v-tabs icons-and-text>
            <v-tabs-slider color="error"></v-tabs-slider>
            <v-tab :to="{ name: 'PersonContact' }"
              >Kontakt<v-icon>mdi-email</v-icon>
            </v-tab>
            <v-tab :to="{ name: 'PersonProfile' }">
              Profil<v-icon>mdi-bank</v-icon></v-tab
            >
            <v-tab :to="{ name: 'PersonCourses' }">
              Kurse<v-icon>mdi-human-male-board</v-icon></v-tab
            >
            <v-tab v-if="student" :to="{ name: 'PersonAbsences' }"
              >Absenzen<v-icon>mdi-account-minus</v-icon></v-tab
            >
            <v-tab v-if="teacher" :to="{ name: 'PersonAbsencesTeacher' }"
              >Abwesenheiten<v-icon>mdi-bed</v-icon></v-tab
            >
            <v-tab v-if="sysAdmin" :to="{ name: 'PersonAccount' }">
              Konto<v-icon>mdi-badge-account-horizontal-outline</v-icon></v-tab
            >
            <v-tab v-if="sysAdmin" :to="{ name: 'PersonGroups' }"
              >Gruppen<v-icon>mdi-vector-triangle</v-icon>
            </v-tab>
            <v-tab :to="{ name: 'PersonPortfolios' }">
              Portfolios<v-icon>mdi-certificate</v-icon></v-tab
            >
            <v-tab v-if="student" :to="{ name: 'PersonPrint' }">
              Drucken<v-icon>mdi-printer</v-icon></v-tab
            >
            <v-tab v-if="student" :to="{ name: 'PersonReport' }">
              Berichte<v-icon>mdi-text-box-multiple</v-icon></v-tab
            >
            <v-tab :to="{ name: 'PersonLog' }">
              Log<v-icon>mdi-database</v-icon></v-tab
            >
          </v-tabs>
          <v-divider class="mb-4" />
          <router-view :value="person"></router-view>
        </v-card>
      </v-col>
      <v-col class="col-auto">
        <v-navigation-drawer app clipped permanent right>
          <v-list nav dense>
            <div v-for="(item, index) in items" :key="index">
              <v-subheader>{{ item.description }}</v-subheader>
              <v-list-item-group>
                <PersonItem
                  v-for="item in item.items"
                  :key="item.id"
                  :value="item"
                  clickable
                  nodetails
                  @click="selectPerson(item)"
                />
              </v-list-item-group>
            </div>
          </v-list>
        </v-navigation-drawer> </v-col
    ></v-row>
  </v-container>
</template>
<script>
import CopyButton from "@/components/CopyButton.vue";
import PersonItem from "@/components/PersonItem.vue";
import PersonName from "common/components/PersonName.vue";
import PortraitImage from "common/components/PortraitImage.vue";
import SearchField from "@/components/SearchField.vue";
import { sortPeople, personSubtitle } from "common/utils/people.js";
export default {
  name: "People",
  components: {
    CopyButton,
    PersonItem,
    PersonName,
    PortraitImage,
    SearchField,
  },
  props: ["id"],
  data() {
    return {
      person: {},
      items: [],
      search: "",
      selectedId: this.id,
      role: "",
      loading: false,
    };
  },
  computed: {
    student() {
      return this.person.type && this.person.type.id === 4;
    },
    teacher() {
      return this.person.type && this.person.type.id === 2;
    },
    subtitle() {
      let result = personSubtitle(this.person);
      if (this.person.eventoId) {
        result += ", Evento-ID: " + this.person.eventoId;
      }
      return result;
    },
    sysAdmin() {
      return this.$_hasRole("sysAdmin");
    },
  },
  watch: {
    async search() {
      this.doSearch();
    },
    id() {
      this.selectedId = this.id;
      this.getData();
    },
  },
  methods: {
    async getData() {
      if (this.selectedId > 0) {
        this.loading = true;
        this.person = await this.apiGet({
          resource: "person/person",
          id: this.selectedId,
        });
        this.loading = false;
      }
    },
    doSearch() {
      // cancel pending call
      clearTimeout(this._searchTimerId);

      // delay new call 500ms
      const search = this.search;
      // only search for more than 2 chars
      if (!search || search.length < 3) return;
      this._searchTimerId = setTimeout(async () => {
        this.loading = true;
        this.items = sortPeople(
          await this.apiList({
            resource: "person/person",
            query: `search=${search}`,
          })
        );
        localStorage.setItem("personSearch", search);
        this.loading = false;
      }, 500);
    },
    selectPerson(item) {
      this.selectedId = item.id;
      this.getData();
    },
  },
  async created() {
    if (localStorage.getItem("personSearch")) {
      this.search = localStorage.getItem("personSearch");
    }
    this.getData();
  },
};
</script>
